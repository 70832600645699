@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');

* {
    font-family: 'Heebo', sans-serif;
    padding: 0;
    margin: 0;
    /* border: 1px red solid;  */
}

.red-text {
    color: #f43f5e;
    font-weight: 600;
}

strong {
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}

:root {
    --primary-color : #06283D;
    --light-color : #ccfbf1;
    --dark-text-color : #111827;
    --blue-color : #2146C7;
    --light-blue : #ecfeff;
}

/* button element */
.btn {
    padding: 8px 16px;
    border: 1px solid;
    border-radius: 12px;
}

.primary-button {
    background-color: var(--dark-text-color);
    color: white;
    border: 1px solid var(--dark-text-color);
}

.secondary-button {
    background-color: white;
    color: var(--blue-color);
}

.start-button {
    background-color: white;
    color: #16a34a;
    border: 1px solid #16a34a;
}

.start-button:hover{
    background-color: #16a34a;
    color: white;
}

.end-button {
    background-color: white;
    color: #e11d48;
    border: 1px solid #e11d48;
}

.end-button:hover{
    background-color: #e11d48;
    color: white;
}


.primary-button:hover{
    background-color: var(--dark-text-color);
    color: var(--light-blue);
    border: 1px solid var(--dark-text-color);
}

.secondary-button:hover{
    background-color: var(--blue-color);
    color: white;
}

.margin-left {
    margin-left: 2rem;
}

.margin-right{
    margin-right: 2rem;
}

.options {
    background-color: white;
    color: #e11d48;
    border: 1px solid #e11d48;
    font-size: 14px;
}

.options:hover{
    background-color: #e11d48;
    color: white;
}

.chosen-button {
    background-color: #e11d48;
    color: white;
}

/* navbar section */
.navbar {
    padding: 1.2rem 3rem;
    background-color: var(--light-color);
}

.nav-list {
    display: flex;
    justify-content: flex-end;
    font-size: 1.1rem;
    align-content: center;
}

.nav-list li {
    list-style: none;
    text-decoration: none;
    margin: 0 10px;
}

.nav-list li .link {
    color: var(--dark-text-color);
    text-decoration: none;
}

.nav-list li .link:hover {
    text-decoration: underline;
}

.hero-text {
    margin-top: 1rem;
    font-size: 2rem;
}

/* styling a whole screen */
.screen {
    margin: 0;
    /* padding: 10px 25px; */
    text-align: center;
}

.landing-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form {
    margin: 0;
    padding: 20px 0px;
}

.form input {
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* width: 15%; */
    padding: 10px 12px;
    font-size: 13px;
    border-radius: 12px;
    margin: 1rem 0rem;
    border: var(--primary-color) 1px solid
};

.form .button-cta {
    padding: 10px 12px;
    border-radius: none;
    border: none;
    background-color: var(--primary-color);
    color: var(--light-color);
    font-size: 13px;
    border-radius: 12px;
    padding: 12px 15px;
    margin: 1rem 0rem;
    width: 17%;
}

/* simulation screen */
.choices {
    display: flex;
    justify-content: space-evenly;
}

.user-details {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    text-align: left;
}

.instruction {
    font-size: 1.2rem;
    margin: 1.5rem 0rem;
    margin-bottom: 3rem;
}

/* styling the career card */

.career-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px;
    width: 200px;
    padding: 12px 15px;
    border: 1px solid black;
    border-radius: 12px;
    align-items: center;
}

.career-card code {
    background-color: #FF6464;
    border-radius: 50%;
    width: 25px;
    padding: 1.5px;
    color: white;
    text-align: center;
}

.career-card h2 {
    font-weight: 500;
}

.career-card button {
    background-color: var(--dark-text-color);
    color: white;
    border: 1px solid var(--dark-text-color);
    padding: 8px;
    border-radius: 12px;
    width: 75%;
}

.career-card button:hover{
    background-color: var(--dark-text-color);
    color: white;
}

.chosen-btn {
    background-color: var(--dark-text-color);
    color: gold;
}


/* admin screen */
.admin-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.1rem;
    padding: 25px 10px;
};

.screen .admin-hero {
    padding: 100px 20px;
}

.screen .admin-hero .admin-options-flex {
    display: flex;
    justify-content: center;
}


/* chart page */
.chart-canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    padding-bottom: 200px;
    padding-top: 0px;
}


/* choice card */
.choice-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 15px 30px;
    border-radius: 12px;
}

.choice-card h3 {
    font-size: 1.5rem;
}

.onboarding-choice {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
    padding: 10px 15px;
}

/* game explanation section */

.instructions {
    text-align: left;
    padding: 2rem 10rem;
};

.instructions a{
    text-decoration: none;
    color: #1d4ed8;
}

.explanation-iframe {
    border-radius: 12px;
    width: 10rem;
    height: 10rem
};



.create-room-btn {
    margin-left: 3rem;
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid;
    background-color: var(--primary-color);
    color: white;
}

.explanation a {
    text-decoration: none;
    color: #1d4ed8;
};

/* debrief */
.explanation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    text-align: left;
    padding: 10px 30px;
}

.explanation iframe {
    border-radius: 12px;
    width: 600px;
}


/* flex */
.flex {
    display: flex;
    align-items: center;
}

.feedback-link {
    padding: 10px 30px;
    text-align: center;
}